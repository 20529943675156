import React, { createContext, ReactNode, useContext, useState } from "react";
import { checkPayoutDate } from "../utils/helpers";

interface IPaymentPayload {
  amount: number;
  fee: number;
  emergency: boolean;
  emergencyCharge: number;
  youReceive: number;
  account: string;
  date: string | undefined;
  id:string
}

interface PaymentContextValue {
  paymentData: IPaymentPayload;
  setPaymentData: React.Dispatch<React.SetStateAction<IPaymentPayload>>;
}

const initialPaymentData: IPaymentPayload = {
  amount: 0,
  fee: 0,
  emergency: false,
  emergencyCharge: 0,
  youReceive: 0,
  account: "",
  date: checkPayoutDate(),
  id:""
};

const PaymentContext = createContext<PaymentContextValue | undefined>(
  undefined
);

export const PaymentProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [paymentData, setPaymentData] = useState(initialPaymentData);

  return (
    <PaymentContext.Provider value={{ paymentData, setPaymentData }}>
      {children}
    </PaymentContext.Provider>
  );
};

export const usePayment = () => {
  const context = useContext(PaymentContext);
  if (!context) {
    throw new Error("usePayment must be used within a PaymentProvider");
  }
  return context;
};
