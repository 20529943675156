import React from "react";
import "./balance-card.scss";
import { currencyFormat } from "../../../utils/helpers";
interface BalanceCardProps {
  balance: number;
  currency?: string;
  onWithdraw: () => void;
  isLoading: boolean;
}

const BalanceCard: React.FC<BalanceCardProps> = ({
  isLoading,
  balance,
  currency = "$",
  onWithdraw,
}) => {
  return (
    <div className="balance-card">
      <div className="card-content">
        <h3>Total Balance</h3>
        {isLoading || !balance ? (
          <p className="balance-amount">....</p>
        ) : (
          <p className="balance-amount">
            {currencyFormat(balance)}
            {/* Formats balance with two decimal points */}
          </p>
        )}

        {/* Withdraw Button */}
        <button className="withdraw-button" onClick={onWithdraw}>
          Withdraw
        </button>
      </div>
    </div>
  );
};

export default BalanceCard;
