import Layout from "../../layout/Portal";
import "./styles.scss";
import Button from "../../../components/Button";
import Icon from "../../../components/Icon";
import { useNavigate } from "react-router-dom";
import Toggle from "react-toggle";
import { useCallback, useEffect, useState } from "react";
import "react-toggle/style.css";
import { checkPayoutDate, currencyFormat } from "../../../utils/helpers";
import { toast } from "sonner";
import { usePayment } from "../../../context/PaymentContext";

interface PaymentMethod {
  id: string;
  type: string;
  accountEnding: string;
  isPrimary: boolean;
  details: {
    payoneerId: string;
  };
}

export const Withdraw = () => {
  const navigate = useNavigate();
  const [values, updateValues] = useState({
    data: "",
    loading: false,
    error: "",
  });

  const [valuesPayID, updateValuesPayID] = useState<{
    data: PaymentMethod[];
    loading: boolean;
    error: string;
  }>({
    data: [],
    loading: false,
    error: "",
  });

  const [toggle, setToggle] = useState(false);
  const [amount, setAmount] = useState("");

  const [selectedPayId, setSelectedPayId] = useState<PaymentMethod>({
    id: "",
    type: "",
    accountEnding: "",
    isPrimary: false,
    details: {
      payoneerId: "",
    },
  });

  const [showDrop, setShowDrop] = useState(false);

  const { paymentData, setPaymentData } = usePayment();

  const _submitHandler = () => {
    if (parseInt(amount) < 60 || !amount) {
      toast.warning("Minimum withdrawal amount is 60 USD", {
        richColors: true,
      });
    } else if (parseInt(amount) > parseInt(values.data)) {
      toast.warning(
        "You cannot initiate a withdrawal more than your current balance!",
        {
          richColors: true,
        }
      );
    } else if (!selectedPayId.details.payoneerId) {
      toast.warning("Select or add a payment ID!", {
        richColors: true,
      });
    } else {
      const calcTotal = () => {
        const parsedAmount = parseInt(amount);
        if (isNaN(parsedAmount)) return 0;

        const emergencyCharge = parsedAmount * 0.05;
        const totalCharge = parsedAmount - 5;

        const totalFee = emergencyCharge + 5;
        return toggle ? parsedAmount - totalFee : totalCharge;
      };

      setPaymentData({
        ...paymentData,
        account: selectedPayId.details.payoneerId,
        amount: parseInt(amount),
        youReceive: calcTotal(),
        emergency: toggle,
        fee: parseInt(amount) - 5,
        emergencyCharge: parseInt(amount) * 0.05,
        id: selectedPayId.id,
      });
      navigate("./summary");
    }
  };

  const fetchPayId = useCallback(async () => {
    const token = await localStorage.getItem("accessToken");

    updateValuesPayID({ ...valuesPayID, loading: true, error: "" });
    const url = `https://pluri-api-service.onrender.com/payment-method`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          authorization: `Bearer ${token}` ?? "",
          "Content-Type": "application/json",
        },
      });

      const result = await response.json();
      console.log(result);

      if (result) {
        updateValuesPayID({
          ...valuesPayID,
          data: result,
          loading: false,
        });

        const defaultPayId = result.find(
          (item: PaymentMethod) => item.isPrimary
        );

        if (defaultPayId?.details?.payoneerId) {
          setSelectedPayId(defaultPayId || "");
        }
      } else {
        updateValuesPayID({
          ...valuesPayID,
          loading: false,
          error: result.message ?? "Something went wrong",
        });
      }
    } catch (err: any) {
      console.log(err);
      updateValuesPayID({
        ...valuesPayID,
        loading: false,
        error: err.message ?? "Something went wrong",
      });
    }
  }, []);

  const fetchBalance = useCallback(async () => {
    const token = await localStorage.getItem("accessToken");
    const id = await localStorage.getItem("id");
    updateValues({ ...values, loading: true, error: "" });
    const url = `https://pluri-api-service.onrender.com/teachers/balance/${id}`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          authorization: `Bearer ${token}` ?? "",
          "Content-Type": "application/json",
        },
      });

      const result = await response.json();

      if (result) {
        updateValues({
          ...values,
          data: result.result,
          loading: false,
        });
      } else {
        updateValues({
          ...values,
          loading: false,
          error: result.message ?? "Something went wrong",
        });
      }
    } catch (err: any) {
      console.log(err);
      updateValues({
        ...values,
        loading: false,
        error: err.message ?? "Something went wrong",
      });
    }
  }, []);

  useEffect(() => {
    fetchBalance();
    fetchPayId();
  }, [fetchBalance, fetchPayId]);

  return (
    <Layout>
      <Button
        onClick={() => navigate(-1)}
        variant="neutral"
        className="go-back"
      >
        <Icon name="left_arrow" />
        <p className="back">Back</p>
      </Button>
      {values.loading || valuesPayID.loading ? (
        <p>Loading</p>
      ) : values.error || valuesPayID.error ? (
        <p>Something went wrong</p>
      ) : (
        <div className="withdraw-content-container">
          <h3 className="w-title">Withdraw Your Earnings</h3>
          {toggle ? (
            <div>
              <p className="w-notice">Emergency Withdrawal Fee Notice!</p>
              <p className="w-notice-subtitle">
                Choosing an emergency withdrawal will include an additional fee
                of 5% of withdrawal amount, this is to ensure faster processing
                to meet your urgent needs.{" "}
              </p>
            </div>
          ) : (
            <p className="w-message">
              {`Your Withdrawal will be processed ${checkPayoutDate()}
              Requests made within 48 hours before 14th and 28th, will be processed
              on the next payout day, except in cases of emergencies, which are
              processed within 48 hours. You will be notified once your
              withdrawal is completed. For any delays or inquiries, please
              contact our support team.`}
            </p>
          )}
          <p className="w-balance">Wallet Balance</p>
          <p className="w-amount">{currencyFormat(parseInt(values.data))}</p>

          <label className="w-label">Enter withdrawal amount (USD)</label>
          <br />

          <input
            value={amount}
            defaultValue={amount}
            onChange={(e) => {
              const replacedText = e.target.value
                .replace(/[^0-9.]/g, "")
                .replace(/^0(?!\.)/, "")
                .replace(/(\.\d{2})\d+/, "$1")
                .replace(/^([1-9]\d*(\.\d{0,2})?).*$/, "$1");

              setAmount(replacedText);
            }}
            className="w-input"
            placeholder="E.g. 60"
            type="number"
          />

          <p className="w-account">Withdrawal account</p>
          <div className="w-user-account">
            <p className="w-account-details">
              {selectedPayId.details.payoneerId}
            </p>
            <p
              onClick={() => setShowDrop(!showDrop)}
              className="w-change-account"
            >
              {showDrop ? "Close" : "Change"}
            </p>
            {showDrop && (
              <div className="dropdown">
                {valuesPayID.data.length < 1 ? (
                  <p className="payMethodText">
                    You haven't added a payout account yet. <br /> Please go to
                    your <a href="../profile">profile</a> and provide the
                    required information.
                  </p>
                ) : (
                  valuesPayID.data.map((item, index) => {
                    return (
                      <div
                        onClick={() => {
                          setSelectedPayId(item);
                          setShowDrop(false);
                        }}
                      >
                        <p className="payMethodText">
                          {item.details.payoneerId}
                        </p>
                      </div>
                    );
                  })
                )}
              </div>
            )}
          </div>
          <div className="w-emergency-container">
            <div>
              <p className="w-emergency">Emergency Withdrawal</p>
              <p className="w-48hrs">Process within 48hrs</p>
            </div>
            <label>
              <Toggle
                defaultChecked={toggle}
                icons={false}
                onChange={() => {
                  setToggle(!toggle);
                }}
              />
            </label>
          </div>
          <div className="w-proceed-container">
            <button
              disabled={values.loading || !values.data ? true : false}
              onClick={() => _submitHandler()}
              className="w-proceed"
            >
              Proceed
            </button>
          </div>
        </div>
      )}
    </Layout>
  );
};
