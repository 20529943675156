import { useParams } from "react-router-dom";
import Layout from "../../layout/Portal";
import "./index.scss";

import {
  formatDateString,
  isLessonInProgress,
  isLessonInPast,
  formatTimeSlot,
} from "../../../utils/helpers";
import Icon from "../../../components/Icon";
import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ILessonDetails } from "../../../types/schedule";
import { AppLoader } from "../../../components/AppLoader";
import useLanguages from "../../../context/LanguagesContext";
import { useMemo } from "react";
import Button from "../../../components/Button";
import { toast } from "sonner";

export default function ScheduleDetail() {
  const { languages_taught } = useLanguages();
  const { lesson_id } = useParams();
  const queryClient = useQueryClient();

  const enterClassRoom = () => {};
  const markCompleted = async (id: string) => {
    try {
      // Endpoint URL
      const url = `${process.env.REACT_APP_API_BASE_URL}/bookings/mark-completed/${id}`;
      const data = {
        status: "completed",
      };

      // Make the PATCH request
      const response = await axios.patch(url, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`, // Optional: if authentication is needed
        },
      });

      // Log the response
      console.log("Response:", response.data);
    } catch (error) {
      console.error("Error updating resource:", error);
    }
  };

  const { mutate: handleMarkAsCompleted, isPending } = useMutation({
    mutationFn: markCompleted,
    onSuccess() {
      toast.success("Lesson marked as completed");
      queryClient.invalidateQueries({
        queryKey: ["merchant_schedule", lesson_id],
        refetchType: "all",
      });
      queryClient.invalidateQueries({
        queryKey: ["merchant_schedule_upcoming"],
        refetchType: "all",
      });
      queryClient.invalidateQueries({
        queryKey: ["merchant_schedule_completed"],
        refetchType: "all",
      });
    },
    onError(error: any) {
      const message =
        error?.response?.data?.message ??
        error?.data?.message ??
        error?.message ??
        "Failed to mark as completed";
      toast.error(message, { richColors: true });
    },
  });

  const handleButtonClick = () => {
    if (!lesson) return;
    if (
      isLessonInProgress(lesson?.lessonDetails) &&
      lesson.lessonDetails.isCompleted === false
    ) {
      enterClassRoom();
    }

    if (
      isLessonInPast(lesson.lessonDetails) &&
      lesson.lessonDetails.isCompleted === false
    ) {
      handleMarkAsCompleted(lesson_id!);
    }
  };

  const fetchLesson = async (id: string) => {
    try {
      const { data } = await axios(
        `${process.env.REACT_APP_API_BASE_URL}/bookings/lessons/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );

      return data.result as ILessonDetails;
    } catch (error) {
      throw error;
    }
  };

  const { data: lesson, isLoading } = useQuery({
    queryKey: ["merchant_schedule", lesson_id],
    queryFn: () => fetchLesson(lesson_id!),
    enabled: !!lesson_id,
  });

  const language = useMemo(
    () =>
      languages_taught.find((language) => language.id === lesson?.languageId),
    [languages_taught, lesson?.languageId]
  );

  if (isLoading)
    return (
      <div className="loading">
        <AppLoader />
      </div>
    );

  if (!lesson) return <></>;

  return (
    <Layout color={"inherit"}>
      <div className="schedule-lesson">
        <div className="first-row">
          <div>
            <div className="date">
              {formatDateString(new Date(lesson.lessonDetails.time.start))}
            </div>
            <div className="time">
              <span>
                {formatTimeSlot(new Date(lesson.lessonDetails.time.start))}
              </span>{" "}
              -{" "}
              <span>
                {formatTimeSlot(new Date(lesson.lessonDetails.time.end))}
              </span>
            </div>
          </div>
          <h1 className="language">{language?.name}</h1>
        </div>
        <div className="student">
          <h1 className="title">Student</h1>
          <p className="description">{`${lesson.userDetails.firstName} ${lesson.userDetails.lastName}`}</p>
        </div>
        <div className="lesson">
          <h1 className="title">Lesson Package</h1>
          <h3 className="lesson--title">{lesson.lessonDetails.lessonTitle}</h3>
          <p className="description">{lesson.lessonDetails.description}</p>
        </div>
        <div className="classroom">
          <div className="column">
            <h1 className="title">Classroom</h1>
            <div className="bottom">
              <Icon name="zoom" />
              <p>{lesson.lessonDetails.venue.platform} Classroom</p>
            </div>
          </div>
          <div className="column">
            <h1 className="title">Classroom ID</h1>
            <div className="bottom">
              <p>{lesson.lessonDetails.venue.meetingId}</p>
              <Icon name="copy" />
            </div>
          </div>
          <div className="column">
            <h1 className="title">Classroom Passcode</h1>
            <div className="bottom">
              <p>{lesson.lessonDetails.venue.passcode}</p>
              <Icon name="copy" />
            </div>
          </div>
        </div>
        <Button
          variant="primary"
          className="action"
          isLoading={isPending}
          disabled={isPending || lesson.lessonDetails.isCompleted}
          onClick={handleButtonClick}
        >
          {isLessonInProgress(lesson.lessonDetails)
            ? "Enter classroom"
            : isLessonInPast(lesson.lessonDetails)
            ? lesson.lessonDetails.isCompleted === false
              ? "Mark as completed"
              : "Completed"
            : "Upcoming"}
        </Button>
      </div>
    </Layout>
  );
}
