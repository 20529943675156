import { useNavigate } from "react-router-dom";
import Layout from "../layout/Portal";
import BalanceCard from "./balance-card/BalanceCard";
import Card from "./card/Card";
import "./home.scss";
import { useState, useCallback, useEffect } from "react";

export const Home = () => {
  const balance = 0; // Example balance
  const completedClasses = 0;

  const navigate = useNavigate();

  const handleWithdraw = () => {
    navigate("./withdraw"); // You can replace this with real withdrawal logic, e.g., open a modal or redirect.
  };

  const [values, updateValues] = useState({
    data: "",
    loading: false,
    error: "",
  });

  const fetchBalance = useCallback(async () => {
    const token = await localStorage.getItem("accessToken");
    const id = await localStorage.getItem("id");
    updateValues({ ...values, loading: true, error: "" });
    const url = `https://pluri-api-service.onrender.com/teachers/balance/${id}`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          authorization: `Bearer ${token}` ?? "",
          "Content-Type": "application/json",
        },
      });

      const result = await response.json();

      if (result) {
        updateValues({
          ...values,
          data: result.result,
          loading: false,
        });
      } else {
        updateValues({
          ...values,
          loading: false,
          error: result.message ?? "Something went wrong",
        });
      }
    } catch (err: any) {
      console.log(err);
      updateValues({
        ...values,
        loading: false,
        error: err.message ?? "Something went wrong",
      });
    }
  }, []);

  useEffect(() => {
    fetchBalance();
  }, []);

  return (
    <Layout>
      <h2>Welcome to your Dashboard</h2>

      {/* Container for the cards */}
      <div className="dashboard-cards">
        {/* Display the balance card */}
        <BalanceCard
          isLoading={values.loading}
          balance={parseInt(values.data)}
          onWithdraw={handleWithdraw}
        />

        {/* Display the transactions card */}
        <Card totalTransactions={completedClasses} />
      </div>
    </Layout>
  );
};
