import React from "react";
import Layout from "../../layout/Portal";
import "./styles.scss";
import Button from "../../../components/Button";
import Icon from "../../../components/Icon";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { useState, useCallback } from "react";
import { usePayment } from "../../../context/PaymentContext";

export const Summary = () => {
  const navigate = useNavigate();

  const { paymentData } = usePayment();
  console.log(paymentData);
  const [values, updateValues] = useState({
    data: "",
    loading: false,
    error: "",
  });

  const _withdrawalHandler = async () => {
    const token = await localStorage.getItem("accessToken");
    const url = `https://pluri-api-service.onrender.com/withdrawal`;

    updateValues({ ...values, loading: true, error: "" });

    const raw = JSON.stringify({
      // amount: paymentData.youReceive,
      amount: paymentData.amount,
      paymentMethodId: paymentData.id,
      isEmergency: paymentData.emergency,
    });

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: raw,
      });

      const result = await response.json();

      if (response.ok) {
        updateValues({
          ...values,
          data: result.result,
          loading: false,
        });
        toast.success("Withdrawal has been initiated successfully", {
          richColors: true,
        });
        navigate(-1);
      } else {
        const errorMessage = result.message || "Something went wrong";
        toast.error(errorMessage, {
          richColors: true,
        });
        updateValues({
          ...values,
          loading: false,
          error: errorMessage,
        });
      }
    } catch (err: unknown) {
      const errorMessage =
        err instanceof Error ? err.message : "An unknown error occurred";
      toast.error(errorMessage, {
        richColors: true,
      });
      console.error(err);
      updateValues({
        ...values,
        loading: false,
        error: errorMessage,
      });
    }
  };

  return (
    <Layout>
      <Button
        onClick={() => navigate(-1)}
        variant="neutral"
        className="go-back"
      >
        <Icon name="left_arrow" />
        <p className="back">Back</p>
      </Button>
      {values.loading ? (
        <p>Loading</p>
      ) : (
        <div className="withdraw-content-container">
          <h3 className="s-title">Withdrawal Confirmation</h3>

          <div className="row">
            <p className="left-text">Amount withdrawn</p>
            <p className="right-text">{`USD - ${paymentData.amount.toFixed(
              2
            )}`}</p>
          </div>
          <div className="row">
            <p className="left-text">Transaction fee</p>
            <p className="right-text">USD - 5.00</p>
          </div>
          {paymentData.emergency ? (
            <div className="row">
              <p className="left-text">Emergency withdrawal fee (5%)</p>
              <p className="right-text">{`USD - ${paymentData.emergencyCharge.toFixed(
                2
              )}`}</p>
            </div>
          ) : null}
          <div className="row">
            <p className="left-text">You receive</p>
            <p className="right-text">{`USD - ${paymentData.youReceive.toFixed(
              2
            )}`}</p>
          </div>
          <div className="row">
            <p className="left-text">Withdrawal account</p>
            <p className="right-text">{paymentData.account}</p>
          </div>
          <div className="row">
            <p className="left-text">Payment date</p>
            <p className="right-text">
              {paymentData.emergency ? "In 48 hours" : paymentData.date}
            </p>
          </div>
          <div className="s-withdraw-container">
            <button onClick={() => _withdrawalHandler()} className="s-withdraw">
              Proceed
            </button>
          </div>
        </div>
      )}
    </Layout>
  );
};
