import { ILesson } from "../types/schedule";

export const currencyFormat = (
  number: number,
  locale: "en-US" = "en-US",
  currency = "USD"
) => {
  return new Intl.NumberFormat(locale, { style: "currency", currency }).format(
    number
  );
};

export const formatAvailabilityTime = ({
  start,
  end,
}: {
  start: string;
  end: string;
}): string => {
  // Parse the start and end times
  const startDate = new Date(start);
  const endDate = new Date(end);

  // Format the day and month for the output
  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "short",
    year: "numeric",
  };

  const dateStr = startDate.toLocaleDateString("en-US", options);

  // Format time
  const formatTime = (date: Date) =>
    date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });

  // Format the final output
  return `Availability period on ${dateStr.replace(
    ",",
    ""
  )}, is between ${formatTime(startDate)} to ${formatTime(endDate)}`;
};

export const generateTimeSlots = (
  selectedDayString: string,
  selectedSlots: Array<{ day: string; start: string; end: string }>
) => {
  const intervalMinutes = 30;
  const timeSlots: string[] = [];

  const formatTime = (hour: number, minute: number) => {
    return `${hour.toString().padStart(2, "0")}:${minute
      .toString()
      .padStart(2, "0")}`;
  };

  const [month, day, year] = selectedDayString?.split("/").map(Number);
  const selectedDay = new Date(year, month - 1, day);
  const today = new Date();
  const isToday = today.toDateString() === selectedDay.toDateString();

  let currentHour = 6;
  let currentMinute = 0;

  if (isToday) {
    const now = new Date();
    now.setMinutes(
      Math.ceil(now.getMinutes() / intervalMinutes) * intervalMinutes
    );
    if (now.getHours() >= 21) {
      return [];
    }
    currentHour = now.getHours();
    currentMinute = now.getMinutes();
  }

  const unavailableSlots = new Set<string>();
  selectedSlots.forEach((selected) => {
    const slotDay = new Date(selected.day);
    if (slotDay.toDateString() === selectedDay.toDateString()) {
      let startHour = parseInt(selected.start.split(":")[0]);
      let startMinute = parseInt(selected.start.split(":")[1]);
      let endHour = parseInt(selected.end.split(":")[0]);
      let endMinute = parseInt(selected.end.split(":")[1]);

      while (
        startHour < endHour ||
        (startHour === endHour && startMinute <= endMinute)
      ) {
        unavailableSlots.add(formatTime(startHour, startMinute));
        startMinute += intervalMinutes;
        if (startMinute >= 60) {
          startMinute -= 60;
          startHour++;
        }
      }
    }
  });

  while (currentHour < 21 || (currentHour === 21 && currentMinute === 0)) {
    const startTime = formatTime(currentHour, currentMinute);
    if (!unavailableSlots.has(startTime)) {
      timeSlots.push(startTime);
    }
    currentMinute += intervalMinutes;
    if (currentMinute >= 60) {
      currentMinute -= 60;
      currentHour++;
    }
  }

  return timeSlots;
};

export const generateEndTimeSlots = (startTime: string) => {
  const intervalMinutes = 30;
  const timeSlots: string[] = [];
  const [startHour, startMinute] = startTime.split(":").map(Number);

  let availableHour = startHour;
  let availableMinute = startMinute + intervalMinutes;

  const formatTime = (hour: number, minute: number) => {
    return `${hour.toString().padStart(2, "0")}:${minute
      .toString()
      .padStart(2, "0")}`;
  };

  if (availableMinute >= 60) {
    availableMinute -= 60;
    availableHour++;
  }

  while (
    availableHour < 21 ||
    (availableHour === 21 && availableMinute === 0)
  ) {
    const currentTime = formatTime(availableHour, availableMinute);
    timeSlots.push(currentTime);

    availableMinute += intervalMinutes;
    if (availableMinute >= 60) {
      availableMinute -= 60;
      availableHour++;
    }
  }

  return timeSlots;
};

export const getWeekdayTimeSlots = (
  dateRange: { from: string; to: string },
  weekdayName: string,
  startEndTime: Array<{ start: string; end: string; day: string }>
) => {
  if (!dateRange.from || !dateRange.to || !weekdayName) {
    return startEndTime;
  }

  const dayMapping: { [key: string]: number } = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
  };

  const result: Array<{ end: string; start: string; day: string }> = [
    ...startEndTime,
  ];
  const fromDate = new Date(dateRange.from);
  const toDate = new Date(dateRange.to);
  const targetWeekday = dayMapping[weekdayName];

  const formatDate = (date: Date) =>
    `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;

  while (fromDate.getDay() !== targetWeekday) {
    fromDate.setDate(fromDate.getDate() + 1);
  }

  for (
    let currentDate = new Date(fromDate);
    currentDate <= toDate;
    currentDate.setDate(currentDate.getDate() + 7)
  ) {
    const formattedDate = formatDate(currentDate);

    const isAlreadyPresent = startEndTime.some(
      (item) => item.day === formattedDate
    );

    if (!isAlreadyPresent) {
      startEndTime.forEach((timeSlot) => {
        result.push({
          end: timeSlot.end,
          start: timeSlot.start,
          day: formattedDate,
        });
      });
    }
  }

  return result;
};

export const formatDateToLong = (dateString: any): string => {
  const date = new Date(dateString);

  const options: Intl.DateTimeFormatOptions = {
    weekday: "long",
    day: "numeric",
    month: "long",
    year: "numeric",
  };

  return new Intl.DateTimeFormat("en-US", options).format(date);
};

export const convertIndexToSession = (index: number): string => {
  const ordinals = [
    "First",
    "Second",
    "Third",
    "Fourth",
    "Fifth",
    "Sixth",
    "Seventh",
    "Eighth",
    "Ninth",
    "Tenth",
  ];
  if (index >= 0 && index < ordinals.length) {
    return `${ordinals[index]} session`;
  } else {
    return `Session ${index + 1}`;
  }
};

//takes in Date and returns in format Tuesday, 30 October, 2024
export const formatDateString = (date: Date): string => {
  const formatter = new Intl.DateTimeFormat("en-GB", {
    weekday: "long",
    day: "2-digit",
    month: "long",
    year: "numeric",
  });

  return formatter.format(date);
};

type TimeSlot = `${string}${"am" | "pm"}`;

export const formatTimeSlot = (time: Date): TimeSlot => {
  const h = time.getHours();
  const hour = (h === 12 ? 12 : h % 12).toString().padStart(2, "0");
  const minute = time.getMinutes().toString().padStart(2, "0");
  const meridian = h >= 12 ? "pm" : "am";
  return `${hour}:${minute}${meridian}`;
};

export const sortLessons = (
  currentLesson: ILesson,
  nextLesson: ILesson
): number => {
  const startTime = new Date(currentLesson.time.start);
  const endTime = new Date(nextLesson.time.end);
  return startTime.getTime() - endTime.getTime();
};

export function isLessonInProgress<T extends { time: ILesson["time"] }>(
  lesson: T
): boolean {
  const { time } = lesson;

  const startTime = new Date(time.start);
  const endTime = new Date(time.end);
  const now = new Date();

  return now >= startTime && now <= endTime;
}

export function isLessonInPast<T extends { time: ILesson["time"] }>(
  lesson: T
): boolean {
  const { time } = lesson;

  const endTime = new Date(time.end);

  const now = new Date();

  return now > endTime;
}

export const isScreenLarge = () => {
  return window.matchMedia("(max-width: 768px)").matches;
};

export const checkPayoutDate = () => {
  const now = new Date();
  const day = now.getDate();
  const month = now.getMonth();

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  if (day >= 26 || day <= 12) {
    // January 26th to February 12th
    const nextMonth = day >= 26 ? month + 1 : month;
    const payoutMonth = monthNames[nextMonth % 12];
    return `on the 14th of ${payoutMonth},`;
  } else if (day >= 13 && day <= 25) {
    // February 13th to February 25th
    const payoutMonth = monthNames[month];
    return `on the 28th of ${payoutMonth},`;
  }
};
