import React, { useState, useEffect, useCallback } from "react";
import "./walletStyles.scss";
import Icon from "../../components/Icon";
import { toast } from "sonner";
import Toggle from "react-toggle";
import "react-toggle/style.css";

interface StepProps {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setSelectedItem: React.Dispatch<React.SetStateAction<PaymentMethod>>;
  selectedItem: PaymentMethod;
}

interface PaymentMethod {
  id: string;
  type: string;
  accountEnding: string;
  isPrimary: boolean;
  details: {
    payoneerId: string;
  };
}

const Step1: React.FC<StepProps> = ({
  setCurrentStep,
  setSelectedItem,
  selectedItem,
}) => {
  const _addMethod = () => {
    setCurrentStep(2);
  };

  const [values, updateValues] = useState<{
    data: PaymentMethod[];
    loading: boolean;
    error: string;
  }>({
    data: [],
    loading: false,
    error: "",
  });

  const fetchPayId = useCallback(async () => {
    const token = await localStorage.getItem("accessToken");

    updateValues({ ...values, loading: true, error: "" });
    const url = `https://pluri-api-service.onrender.com/payment-method`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          authorization: `Bearer ${token}` ?? "",
          "Content-Type": "application/json",
        },
      });

      const result = await response.json();

      if (result) {
        updateValues({
          ...values,
          data: result,
          loading: false,
        });
      } else {
        updateValues({
          ...values,
          loading: false,
          error: result.message ?? "Something went wrong",
        });
      }
    } catch (err: any) {
      console.log(err);
      updateValues({
        ...values,
        loading: false,
        error: err.message ?? "Something went wrong",
      });
    }
  }, []);

  const deletePayID = async (id: string) => {
    const token = await localStorage.getItem("accessToken");
    updateValues({ ...values, loading: true, error: "" });
    const url = `https://pluri-api-service.onrender.com/payment-method/${id}`;

    try {
      const response = await fetch(url, {
        method: "DELETE",
        headers: {
          authorization: `Bearer ${token}` ?? "",
          "Content-Type": "application/json",
        },
      });

      const result = await response.json();

      if (response.ok) {
        toast.success("Payoneer ID has been deleted successfully", {
          richColors: true,
        });
        updateValues({ ...values, loading: false, error: "" });
        fetchPayId();
      } else {
        toast.error(result.message ?? "Something went wrong", {
          richColors: true,
        });
        updateValues({ ...values, loading: false, error: "" });
      }
    } catch (err: any) {
      updateValues({ ...values, loading: false, error: "" });
      toast.error(err.message ?? "Something went wrong", {
        richColors: true,
      });
    }
  };

  useEffect(() => {
    fetchPayId();
  }, []);

  return values.loading ? (
    <p>Loading</p>
  ) : (
    <div>
      <div className="walletContentContainer">
        <p className="wallet-title">Set withdrawal method</p>
        <button className="createBtnAdd" onClick={() => _addMethod()}>
          + add method
        </button>
      </div>
      {values.data.length < 1 ? (
        <p className="title">You are yet to add a payment method</p>
      ) : (
        values.data.map((item, index) => {
          return (
            <div className="payoneerContainer">
              <div>
                <p className="payoneer">{item.type}</p>
                <p className="account">{item.details.payoneerId}</p>
                {item.isPrimary ? <p className="default">default</p> : null}
              </div>
              <div className="editDelete">
                <p
                  onClick={() => {
                    setSelectedItem(item);
                    setCurrentStep(4);
                  }}
                  className="edit"
                >
                  edit
                </p>
                <p onClick={() => deletePayID(item.id)} className="delete">
                  delete
                </p>
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};

const Step2: React.FC<StepProps> = ({
  setCurrentStep,
  setSelectedItem,
  selectedItem,
}) => {
  const [values, updateValues] = useState({
    data: "",
    loading: false,
    error: "",
  });

  return (
    <div>
      <div
        onClick={() => setCurrentStep(1)}
        className="walletContentContainer2"
      >
        <Icon name="left_arrow" />
        <p className="wallet-title2">Add withdrawal method</p>
      </div>
      <p className="title">
        Select where and how you want to receive your earnings.
      </p>
      <div className="payoneerContainer2">
        <div>
          <p className="payoneer">Payoneer</p>
          <p className="payoText">
            Payoneer charges additional fees to withdraw funds. Don't have a 
            <a
              rel="noopener"
              target="_blank"
              href="https://www.payoneer.com/"
              className="link"
            >
              Payoneer account
            </a>
            ?
          </p>
        </div>
        <button onClick={() => setCurrentStep(3)} className="setUp">
          Set up
        </button>
      </div>
    </div>
  );
};

const Step3: React.FC<StepProps> = ({
  setCurrentStep,
  setSelectedItem,
  selectedItem,
}) => {
  const [values, updateValues] = useState({
    data: "",
    loading: false,
    error: "",
  });

  const [text, setText] = useState("");
  const [makeDefault, setMakeDefault] = useState(false);

  const uploadPayID = async () => {
    const token = await localStorage.getItem("accessToken");

    updateValues({ ...values, loading: true, error: "" });
    const url = `https://pluri-api-service.onrender.com/payment-method`;

    const raw = JSON.stringify({
      type: "payoneer",
      details: {
        payoneerId: text,
        isPrimary: makeDefault,
      },
    });

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: raw,
      });

      const result = await response.json();

      if (response.ok) {
        updateValues({
          ...values,
          data: result.result,
          loading: false,
        });
        toast.success("Account added successfully", { richColors: true });
        setCurrentStep(1);
      } else {
        const errorMessage = result.message || "Something went wrong";
        toast.error(errorMessage, { richColors: true });

        updateValues({
          ...values,
          loading: false,
          error: errorMessage,
        });
      }
    } catch (err: unknown) {
      const errorMessage =
        err instanceof Error ? err.message : "An unknown error occurred";
      console.error(err);

      toast.error(errorMessage, { richColors: true });
      updateValues({
        ...values,
        loading: false,
        error: errorMessage,
      });
    }
  };

  return (
    <div>
      <div
        onClick={() => setCurrentStep(2)}
        className="walletContentContainer2"
      >
        <Icon name="left_arrow" />
        <p className="wallet-title2">Set up Payoneer</p>
      </div>
      {values.loading ? (
        <p>Loading</p>
      ) : (
        <div>
          <div className="contentContainer3">
            <p className="payLabel">Enter Your Payoneer ID</p>
            <br />
            <input
              onChange={(e) => setText(e.target.value)}
              className="payInput"
              placeholder="payoneer ID"
            />
          </div>
          <div className="makeDefault">
            <span className="defaultText">Make default</span>
            <Toggle
              defaultChecked={makeDefault}
              icons={false}
              onChange={() => {
                setMakeDefault(!makeDefault);
              }}
            />
          </div>

          <button onClick={() => uploadPayID()} className="doneButton">
            Done
          </button>
        </div>
      )}
    </div>
  );
};

const Step4: React.FC<StepProps> = ({
  setCurrentStep,
  setSelectedItem,
  selectedItem,
}) => {
  const [text, setText] = useState(selectedItem.details.payoneerId);
  const [values, updateValues] = useState({
    data: "",
    loading: false,
    error: "",
  });

  const updatePayID = async () => {
    const token = await localStorage.getItem("accessToken");

    updateValues({ ...values, loading: true, error: "" });
    const url = `https://pluri-api-service.onrender.com/payment-method/${selectedItem.id}`;

    const raw = JSON.stringify({
      details: {
        payoneerId: text,
      },
    });

    try {
      const response = await fetch(url, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: raw,
      });

      const result = await response.json();

      if (response.ok) {
        toast.success("Payoneer ID has been updated successfully", {
          richColors: true,
        });
        updateValues({
          ...values,
          loading: false,
        });
        setCurrentStep(1);
      } else {
        const errorMessage = result.message || "Something went wrong";
        toast.error(errorMessage, { richColors: true });

        updateValues({
          ...values,
          loading: false,
          error: errorMessage,
        });
      }
    } catch (err: unknown) {
      const errorMessage =
        err instanceof Error ? err.message : "An unknown error occurred";
      console.error(err);

      toast.error(errorMessage, { richColors: true });
      updateValues({
        ...values,
        loading: false,
        error: errorMessage,
      });
    }
  };

  return (
    <div>
      <div
        onClick={() => setCurrentStep(1)}
        className="walletContentContainer2"
      >
        <Icon name="left_arrow" />
        <p className="wallet-title2">Set up Payoneer</p>
      </div>
      {values.loading ? (
        <p>Loading</p>
      ) : (
        <div>
          <div className="contentContainer3">
            <p className="payLabel">Edit Your Payoneer ID</p>
            <br />
            <input
              onChange={(e) => setText(e.target.value)}
              defaultValue={selectedItem.details.payoneerId}
              className="payInput"
              placeholder="payoneer ID"
            />
          </div>

          <button onClick={() => updatePayID()} className="doneButton">
            Update
          </button>
        </div>
      )}
    </div>
  );
};

export const WalletSettings: React.FC = () => {
  const [currentStep, setCurrentStep] = React.useState<number>(1);

  const [selectedItem, setSelectedItem] = useState({
    id: "",
    type: "",
    accountEnding: "",
    isPrimary: false,
    details: {
      payoneerId: "",
    },
  });

  const steps: { [key: number]: JSX.Element } = {
    1: (
      <Step1
        setCurrentStep={setCurrentStep}
        setSelectedItem={setSelectedItem}
        selectedItem={selectedItem}
      />
    ),
    2: (
      <Step2
        setCurrentStep={setCurrentStep}
        setSelectedItem={setSelectedItem}
        selectedItem={selectedItem}
      />
    ),
    3: (
      <Step3
        setCurrentStep={setCurrentStep}
        setSelectedItem={setSelectedItem}
        selectedItem={selectedItem}
      />
    ),
    4: (
      <Step4
        setCurrentStep={setCurrentStep}
        setSelectedItem={setSelectedItem}
        selectedItem={selectedItem}
      />
    ),
  };

  return <div className="walletContainer">{steps[currentStep]}</div>;
};
